import { clsx } from '@appvantageasia/core-utils';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { primary, secondary } from '../../../utils/theme';

type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    design?: 'primary' | 'secondary' | 'link' | 'text';
    size?: 'xs' | 's' | 'm' | 'l' | 'xl';
};

const Button = ({ className, size = 'm', design = 'secondary', ...props }: ButtonProps) => (
    <button
        className={clsx(
            'gap-x-1.5 rounded-md font-semibold capitalize',
            design === 'primary' &&
                `${primary.bgColor} text-white shadow-sm ${primary.hoverBgColor} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${primary.focusVisibleOutlineColor}`,
            design === 'secondary' &&
                `bg-white ${secondary.textColor} shadow-sm ring-1 ring-inset ${secondary.ringColor} ${secondary.hoverBgColor}`,
            design === 'link' && `inline border-0 px-0 py-0 ${primary.textColor} shadow-none ring-0`,
            design === 'text' && `inline border-0 px-0 py-0 shadow-none ring-0 focus-visible:outline-none`,
            size === 'xs' && 'gap-x-1.5 px-2 py-1 text-xs leading-5',
            size === 's' && 'gap-x-1.5 px-2 py-1 text-sm leading-6',
            size === 'm' && 'gap-x-1.5 px-2.5 py-1.5 text-sm leading-6',
            size === 'l' && 'gap-x-1.5 px-3 py-2 text-sm leading-6',
            size === 'xl' && 'gap-x-2 px-3.5 py-2.5 text-sm leading-6',
            props.disabled && 'cursor-not-allowed opacity-50',
            props.disabled && design === 'primary' && 'hover:bg-opacity-100',
            props.disabled && design === 'secondary' && '', // todo
            className
        )}
        type="button"
        {...props}
    />
);

export default Button;
